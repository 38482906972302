import React from "react";
import { Helmet } from "react-helmet";
import CookieCompliance from "@/components/CookieCompliance/CookieCompliance";

const About = () => (
  <main>
    <CookieCompliance />
    <Helmet>
      <meta http-equiv="refresh" content="0; URL='/why-ecomtrack/'" />
    </Helmet>
  </main>
);

export default About;
